.modal-backdrop {
    background-color: #17372E;

    &.show {
        opacity: 0.85;
    }

    &.reload-3ds {
        background-color: #1D1D1D;

        &.show {
            opacity: 0.6;
        }
    }
}

.modal {

    .modal-content {
        background-color: transparent;
        border: none;

        .modal-header {
            border: none;
            padding-left: 0;
            padding-right: 0;
        }

        .modal-body {
            // background-color: var(--bs-white);
            border-radius: 8px;
        }

        .modal-footer {
            padding-top: 2rem;
            border: none;
            justify-content: center;
        }

    }

    &.modal-sazonal {

        .modal-dialog {

            .modal-header {

                .close {
                    margin-bottom: -50px;
                    z-index: 99999;
                }
            }

            .modal-body {
                .title {
                    color: var(--bs-primary);
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 29px;
                    line-height: 33px;
                    text-align: center;
                }

                .description {
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    margin: 0;
                }

                .btn {
                    margin: 24px auto;
                }

                .info {
                    font-family: 'Ubuntu';
                    font-style: italic;
                    display: block;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 12px;
                    text-align: center;
                    color: #BCBCBC;
                }
            }

            @include media-breakpoint-down(xl) {

                .modal-header {

                    .close {
                        margin-bottom: 0;
                    }
                }

                .modal-body {
                    .title {
                        font-size: 24px;
                        line-height: 28px;
                    }

                    .description {
                        font-size: 14px;
                        line-height: 22px;
                    }

                    .info {
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
            }
        }
    }

    &.modal-outubro-rosa {
        @extend .modal-sazonal;

        .modal-dialog {
            max-width: 670px;

            .modal-header {

                .close {
                    margin-bottom: 14px;
                }
            }

            .modal-body {

                .info {
                    font-size: 8px;
                    line-height: 8px;
                }
            }

            @include media-breakpoint-down(xl) {

                max-width: 326px;
                margin-right: auto;
                margin-left: auto;

                .modal-header {

                    .close {
                        margin-bottom: 8px;
                    }
                }

                .modal-body {
                    padding: 9px 9px 4px;

                    >.row {

                        >.col {
                            padding: 0 9px;
                        }
                    }

                    .info {
                        font-size: 6px;
                        line-height: 6px;
                    }
                }
            }
        }
    }


    &.modal-esquenta-black-friday {
        @extend .modal-sazonal;

        .modal-dialog {
            max-width: 670px;

            .modal-header {

                .close {
                    margin-bottom: 14px;
                }
            }

            .modal-body {
                margin-top: -30px;

                .info {
                    font-size: 8px;
                    line-height: 8px;
                }

                .loading {
                    top: calc(50% - 1rem);
                    left: calc(50% - 1rem);
                }
            }

            @include media-breakpoint-down(xl) {

                max-width: 326px;
                margin-right: auto;
                margin-left: auto;

                .modal-header {

                    .close {
                        margin-bottom: 8px;
                    }
                }

                .modal-body {
                    padding: 9px 9px 4px;

                    h2 {
                        font-size: 18px;
                        line-height: 22px;
                    }

                    p {
                        font-size: 13px;
                    }

                    >.row {

                        >.col {
                            padding: 0 6px;
                        }
                    }

                    .info {
                        font-size: 6px;
                        line-height: 6px;
                    }
                }
            }
        }
    }

    &.modal-filiacao-black-friday {
        @extend .modal-sazonal;

        .modal-dialog {
            max-width: 670px;

            .modal-header {

                .close {
                    margin-bottom: 14px;
                }
            }

            .modal-body {
                margin-top: -30px;

                .btn-alter {
                    margin: 0;
                    font-size: 16px;
                    padding: 6px 24px;
                    min-height: 0px;

                    border: 2px solid var(--bs-bg-0800);
                }

                .info {
                    font-size: 8px;
                    line-height: 8px;
                }

                .loading {
                    top: calc(50% - 1rem);
                    left: calc(50% - 1rem);
                }
            }

            @include media-breakpoint-down(xl) {

                max-width: 326px;
                margin-right: auto;
                margin-left: auto;

                .modal-header {

                    .close {
                        margin-bottom: 8px;
                    }
                }

                .modal-body {
                    padding: 9px 9px 4px;

                    h2 {
                        font-size: 18px;
                        line-height: 22px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 22px;
                    }

                    >.row {

                        >.col {
                            padding: 0 6px;
                        }
                    }

                    .info {
                        font-size: 6px;
                        line-height: 6px;
                    }
                }
            }
        }
    }

    &.modal-refiliacao,
    &.modal-pix-confirm-whats {

        .modal-body.default {
            padding: 32px;

            h1 {
                font-size: 40px;
                line-height: 48px;
            }

            p {
                font-size: 24px;
                line-height: 32px;
                margin-top: 32px;
            }
        }

        .modal-body.refiliacao {
            height: 581px;
            padding: 32px;
            padding: 90px 48px;

            h1 {
                font-size: 32px;
                line-height: 40px;
            }

            p {
                font-size: 24px;
                line-height: 32px;
                margin-top: 32px;
            }

        }

        .modal-body.refiliacao-cep {
            height: 581px;
            padding: 32px;
            padding: 90px 60px;

            h1 {
                font-size: 32px;
                line-height: 40px;
            }

            p {
                font-size: 24px;
                line-height: 32px;
                margin-top: 32px;
            }

        }

        .modal-body.blackfriday {
            width: 1040px;
            height: 378px;
            padding-top: 62px;
            background-image: url('../../../public/todos/refiliacao/background.jpg');
            margin: 0 auto;

            h1 {
                margin-left: 36px;

                color: #A6FF00;
                font-size: 32px;
                line-height: 40px;
            }

            p {
                margin-top: 32px;
                margin-left: 36px;

                color: white;
                font-size: 16px;
                line-height: 24px;

            }
        }

        @include media-breakpoint-down(xl) {

            .modal-body.default {

                h1 {
                    margin-top: 16px;
                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 16px;

                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }

            }

            .modal-body.refiliacao-cep {
                height: 678px;
                padding: 0px 32px;

                h1 {
                    font-size: 24px;
                    line-height: 32px;
                    margin-top: 16px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 16px;
                }

            }


            .modal-body.refiliacao {
                height: 564px;
                padding: 0px 32px;

                h1 {
                    font-size: 24px;
                    line-height: 32px;
                    margin-top: 16px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 16px;
                }

            }

            .modal-body.blackfriday {
                background-image: url('../../../public/todos/refiliacao/background-mobile.jpg');
                width: 342px;
                height: 418px;
                padding: 32px 8px;

                h1 {
                    margin-top: 32px;
                    margin-left: 10px;

                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 16px;
                    margin-left: 10px;

                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }

            }
        }
    }

    &.modal-pix-confirm-whats {

        @include media-breakpoint-down(xl) {
            h1 {
                text-align: center;
            }

            button {
                width: 100%;
            }

            img {
                width: 180px;
            }
        }
    }

    &.modal-3ds {

        .modal-body.default {

            width: 356px;
            height: 250px;
            margin-left: auto;
            margin-right: auto;

            h1 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
            }

            img {
                // width: 132px;
            }

            .gif {
                clip-path: circle(84% at top);
                animation: infinite;
            }

            .progress-area {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .progress-container {
                position: relative;
                width: 198px;
                height: 24px;
                margin: 20px 0;

                &::after {
                    background-color: #f1f1f1;
                    width: 100%;
                    height: 8px;
                    position: absolute;
                    top: calc(50% - 4px);
                    left: 0;
                    content: "";
                    border-radius: 8px;
                }
            }

            .progress-bar {
                position: absolute;
                height: 24px;
                top: 0;
                left: 0;
                width: 0;
                border-radius: 8px;
                text-align: center;
                transition: width 800ms;

                &::after {
                    background-color: #00A988;
                    width: 100%;
                    height: 8px;
                    position: absolute;
                    top: calc(50% - 4px);
                    left: 0;
                    content: "";
                    z-index: 1;
                    border-radius: 8px;
                }
            }

            .progress-circle {
                position: absolute;
                top: calc(50% - 13px);
                right: -8px;
                z-index: 2;
            }

            .progress-img {
                width: 24px;
                height: 24px;
            }
        }

        @include media-breakpoint-down(xl) {

            .modal-body.default {
                width: 100%;
                height: 283px;

                h1 {
                    margin-top: 10px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }
            }
        }
    }

    &.modal-blackfriday-posfililacao {

        .modal-dialog {
            max-width: 1032px;
            margin-left: auto;
            margin-right: auto;

            .modal-body {
                background-image: url('../../../public/todos/2024/campanhas/zico/modal/bg.png');
                background-size: cover;
                // padding: 54px  90px;
                padding: 0px 90px;
                height: 520px;

                // img {
                //     width: 403px;
                //     margin: 0 auto;
                // }

                h1 {
                    color: #FFD200;
                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 8px;
                    color: white;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0;
                }

                .btn-blackfriday {
                    background: #FFD200;
                    border: none;
                    color: #007D55;
                    // box-shadow: 0px 0px 25px 0px rgba(166, 255, 0, 0.65);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding: 9px 68px;
                    height: auto;
                    min-height: auto;
                    margin-top: 24px;
                    width: 296px;
                }

                .btn-proximo {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-top: 24px;
                    padding: 9px 68px;
                    // padding: 9px 30px;
                    height: auto;
                    min-height: auto;
                    width: 296px;
                }

                .cards {
                    justify-content: flex-start;
                    margin: 0;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                .carousel-indicators {
                    justify-content: flex-start;
                    margin: 0;

                    button {
                        width: 10px;
                        height: 10px;
                        background: white;
                        border: 1px solid #FFD200;
                        border-radius: 50%;
                        opacity: 1;

                        &.active {
                            background: #FFD200;
                        }
                    }
                }
            }
        }


        @include media-breakpoint-down(xl) {

            .modal-dialog {
                max-width: 328px;

                .modal-body {
                    background-image: url('../../../public/todos/2024/campanhas/zico/modal/bg-mobile.png');
                    height: 540px;
                    padding: 8px 16px;

                    img {
                        // width: 424px;
                        // height: 210px;
                    }

                    h1 {
                        font-size: 24px;
                        line-height: 32px;
                        // margin-top: 32px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 24px;
                        margin-top: 8px;
                        font-weight: 400;
                    }

                    .btn-blackfriday {
                        padding: 9px 30px;
                    }

                    .carousel-inner {
                        align-items: flex-start;
                        height: 100%;
                    }

                    .carousel-indicators {
                        justify-content: center;
                        margin: 0;
                        bottom: 45px;
                    }

                }
            }

        }
    }

    &.modal-carnaval-posfililacao {

        .modal-dialog {
            max-width: 1032px;
            margin-left: auto;
            margin-right: auto;

            .modal-body {
                background-image: url('../../../public/todos/bg-carnaval-filiacao.png');
                background-size: cover;
                padding: 54px 90px;

                img {
                    width: 403px;
                    margin: 0 auto;
                }

                h1 {
                    color: #fff;
                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 8px;
                    color: white;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0;
                }

                .btn-blackfriday {
                    background: #A6FF00;
                    border: none;
                    color: #1D1D1D;
                    box-shadow: 0px 0px 25px 0px rgba(166, 255, 0, 0.65);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding: 9px 68px;
                    height: auto;
                    min-height: auto;
                    margin-top: 32px;
                }

                .btn-proximo {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-top: 32px;
                    padding: 9px 30px;
                    height: auto;
                    min-height: auto;

                }

                .carousel-indicators {
                    justify-content: flex-start;
                    margin: 0;

                    button {
                        width: 10px;
                        height: 10px;
                        background: white;
                        border: 1px solid #A6FF00;
                        border-radius: 50%;
                        opacity: 1;

                        &.active {
                            background: #A6FF00;
                        }
                    }
                }
            }
        }


        @include media-breakpoint-down(xl) {

            .modal-dialog {
                max-width: 328px;

                .modal-body {
                    height: 560px;
                    padding: 8px 16px;

                    img {
                        width: 147px;
                    }

                    h1 {
                        font-size: 24px;
                        line-height: 32px;
                        margin-top: 32px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 24px;
                        margin-top: 8px;
                        font-weight: 400;
                    }

                    .btn-blackfriday {
                        padding: 9px 30px;
                    }

                    .carousel-inner {
                        align-items: flex-start;
                        height: 100%;
                    }

                    .carousel-indicators {
                        justify-content: center;
                        margin: 0;
                    }

                }
            }

        }
    }

    &.modal-diadoconsumidor-posfililacao {

        .modal-dialog {
            max-width: 1032px;
            margin-left: auto;
            margin-right: auto;

            .modal-body {
                background-image: url('../../../public/todos/bg-consumidor-filiacao.png');
                background-size: cover;
                padding: 54px 90px;

                img {
                    width: 403px;
                    margin: 0 auto;
                }

                h1 {
                    color: #fff;
                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 8px;
                    color: white;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0;
                }

                .btn-blackfriday {
                    background: linear-gradient(#039310 0%, #024E07 100%);
                    border: none;
                    color: white;
                    box-shadow: 0px 0px 25px 0px linear-gradient(#039310 100%, #024E07 100%);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding: 9px 68px;
                    height: auto;
                    min-height: auto;
                    margin-top: 32px;
                }

                .btn-proximo {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-top: 32px;
                    padding: 9px 30px;
                    height: auto;
                    min-height: auto;

                }

                .carousel-indicators {
                    justify-content: flex-start;
                    margin: 0;

                    button {
                        width: 10px;
                        height: 10px;
                        background: white;
                        border: 1px solid #A6FF00;
                        border-radius: 50%;
                        opacity: 1;

                        &.active {
                            background: #A6FF00;
                        }
                    }
                }
            }
        }


        @include media-breakpoint-down(xl) {

            .modal-dialog {
                max-width: 328px;

                .modal-body {
                    height: 560px;
                    padding: 8px 16px;

                    img {
                        width: 147px;
                    }

                    h1 {
                        font-size: 24px;
                        line-height: 32px;
                        margin-top: 32px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 24px;
                        margin-top: 8px;
                        font-weight: 400;
                    }

                    .btn-blackfriday {
                        padding: 9px 30px;
                    }

                    .carousel-inner {
                        align-items: flex-start;
                        height: 100%;
                    }

                    .carousel-indicators {
                        justify-content: center;
                        margin: 0;
                    }

                }
            }

        }
    }

    &.modal-events {

        .modal-dialog {
            max-width: 1032px;
            margin-left: auto;
            margin-right: auto;

            .modal-body {
                background-image: url('../../../public/todos/2024/saojoao-background.png');
                background-size: cover;
                padding: 54px 90px;

                img {
                    width: 403px;
                    margin: 0 auto;
                }

                h1 {
                    color: #A6FF00;
                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 8px;
                    color: white;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0;
                }

                .text-event {
                    color: #FFFB2A;
                }

                .btn-event {
                    width: 397px;
                    height: 39px;

                    background: #FFFB2A;
                    border: none;
                    color: #1D1D1D;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 23px;
                    padding: 9px 68px;
                    min-height: auto;
                    margin-top: 32px;
                }

                .btn-proximo {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-top: 32px;
                    padding: 9px 30px;
                    height: auto;
                    min-height: auto;

                }

                .carousel-indicators {
                    justify-content: flex-start;
                    margin: 0;

                    button {
                        width: 10px;
                        height: 10px;
                        background: white;
                        border: 1px solid #A6FF00;
                        border-radius: 50%;
                        opacity: 1;

                        &.active {
                            background: #A6FF00;
                        }
                    }
                }
            }
        }


        @include media-breakpoint-down(xl) {

            .modal-dialog {
                max-width: 328px;

                .modal-body {
                    background-image: url('../../../public/todos/2024/saojoao-background.png');
                    height: 560px;
                    padding: 8px 16px;

                    img {
                        width: 296px;
                        height: 280px;
                    }

                    h1 {
                        font-size: 24px;
                        line-height: 32px;
                        margin-top: 32px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 24px;
                        margin-top: 8px;
                        font-weight: 400;
                    }

                    .text-event {
                        color: #FFFB2A;
                    }

                    .btn-event {
                        width: 296px;
                        height: 39px;

                        background: #FFFB2A;
                        border: none;
                        color: #1D1D1D;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        padding: 9px 68px;
                        min-height: auto;
                        margin-top: 32px;
                    }


                    .carousel-inner {
                        align-items: flex-start;
                        height: 100%;
                    }

                    .carousel-indicators {
                        justify-content: center;
                        margin: 0;
                    }

                }
            }

        }
    }

    &.modal-avaliacao {

        .modal-dialog {
            max-width: 1032px;
            margin-left: auto;
            margin-right: auto;

            .modal-body {
                display: flex;
                justify-content: center;

                height: 506px;
                background-color: #FFFFFF;
                padding: 54px 90px;

                img {
                    width: 403px;
                    margin: 0 auto;
                }

                h1 {
                    color: #00A988;
                    font-size: 20px;
                    line-height: 22px;
                }

                p {
                    margin-top: 8px;
                    color: #1D1D1D;
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 0;
                }

                .star-content {
                    margin-top: 16px;
                    height: 25px;
                }

                .star-rating {
                    display: flex;
                    gap: 8px;
                    justify-content: center;
                }

                .star {
                    width: 25px;
                    height: 25px;

                    background: none;
                    border: none;
                    cursor: pointer;
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    transition: color 0.2s;
                }

                .btn-content {
                    text-align: -webkit-center;
                    margin-top: 32px;
                }

                .btn-event {
                    width: 96px;
                    height: 36px;

                    background: #EB5B00;
                    border: none;
                    color: #FFFFFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 23px;
                    min-height: auto;

                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .btn-link {
                    color: #568BE1;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;

                    border: none;
                    background: white;
                }


            }
        }


        @include media-breakpoint-down(xl) {

            .modal-dialog {
                max-width: 328px;

                .modal-body {
                    background-image: url('../../../public/todos/2024/saojoao-background.png');
                    height: 560px;
                    padding: 8px 16px;

                    img {
                        width: 296px;
                        height: 280px;
                    }

                    h1 {
                        font-size: 24px;
                        line-height: 32px;
                        margin-top: 32px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 24px;
                        margin-top: 8px;
                        font-weight: 400;
                    }

                    .text-event {
                        color: #FFFB2A;
                    }

                    .btn-event {
                        width: 296px;
                        height: 39px;

                        background: #FFFB2A;
                        border: none;
                        color: #1D1D1D;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        padding: 9px 68px;
                        min-height: auto;
                        margin-top: 32px;
                    }


                    .carousel-inner {
                        align-items: flex-start;
                        height: 100%;
                    }

                    .carousel-indicators {
                        justify-content: center;
                        margin: 0;
                    }

                }
            }

        }
    }

}